<template>
  <v-container class="container--fluid grid-list-md">
    <DocumentNumberConfirmation :confirmDocumentNumberDialog.sync="confirmDocumentNumberDialog" />
    <v-row no-gutters>
      <v-col cols="12" class="my-2">
        <span class="font-weight-medium text-body-1 primary--text text--darken-1">{{ $t('receipt.receiptActions') }}</span>
        <NextAndPreviousCommon :nextId="nextId" :prevId="prevId" :documentId="receiptId" @idChange="redirectOnNextAndPrevious" />
      </v-col>
    </v-row>
    <v-row no-gutters v-if="(receiptId && !!model.is_locked && model.user_id!==user.id)">
      <v-col cols="12" class="my-3">
        <span class="font-weight-medium text-body-1 primary--text text--darken-1 error--text" >*{{ $t('common.documentReadOnlyModeNotice',{userId:model.user.name}) }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card
          class="mx-auto"
          outlined
        >
          <v-card-title class="py-1">
            <h5 v-if="originModel.recipt_document_number" style="max-width: 175px;" :class="receiptId ? 'px-1 text-truncate font-italic primary--text font-weight-bold' : 'primary--text text--darken-1'" class="font-weight-regular">{{ receiptId ? `${originModel.recipt_document_number}.` : $t('receipt.addReceipt') }}</h5>
            <v-spacer />
            <v-menu bottom v-if="receiptId" :close-on-click="true" :offset-y="true">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="remarksList && remarksList.length > 0 ? 'success' : 'primary'"
                  class="ma-2"
                  outlined
                  small
                  @click="remarksDialog = true"
                  :disabled="(accessRight.length < 2 && accessRight.includes('show'))"
                >
                  {{ $t("remarks.remarks") }}
                </v-btn>
                <v-btn color="primary" class=" ma-2" small outlined v-bind="attrs" v-on="on" :disabled="!valid || (!!model.is_locked && model.user_id!==user.id)">
                  <v-icon left>
                    mdi-menu-down
                  </v-icon>
                  {{$t('quotation.options')}}
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-if="accessRight.includes('create')" dense class="primary--text" @click="copyReceipt">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-content-copy
                      </v-icon>
                      {{$t('quotation.copy')}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense class="primary--text" @click="actDialog = true">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-pulse
                      </v-icon>
                      {{$t('quotation.activityLog')}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense class="primary--text" @click="attachedDocumentsDialog = true">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="primary--text">
                        mdi-file-document
                      </v-icon>
                      {{$t('employee.attachedDocuments')}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense :disabled="selectedStatus == 2" v-if="accessRight.includes('delete')" class="error--text" @click="confirmationDialogStatus = true, delete_item = receiptId">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small class="error--text">
                        mdi-delete-sweep-outline
                      </v-icon>
                      {{$t('common.delete')}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu
              bottom
              v-if="receiptId"
              :close-on-click="true"
              :offset-y="true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="ma-2"
                  small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    !valid || (!!model.is_locked && model.user_id !== user.id)
                  "
                >
                  <v-icon left> mdi-menu-down </v-icon>
                  {{ $t("calendar.calendarEvents") }}
                </v-btn>
              </template>
              <v-list>
                <LoginGoogle />
                <CommonCalendar :docId="receiptId" docType="Receipt" :model="model" />
              </v-list>
            </v-menu>
            <HtmlToPdf v-if="receiptId" class="me-2 mb-1" :disabled="valid && !(!!model.is_locked && model.user_id!==user.id)" :model="originModel" type="ReceiptPDF"/>
            <v-btn
              color="primary"
              small
              :loading="loading"
              :disabled="!valid || (isFieldReadable && !saveOnlyCategoryStatus) && !(isFieldReadable && receiptId && receiptInvoicesModel && receiptInvoicesModel.invoiceData) && ((isFieldReadable && originModel.accounting_update && !!receiptId) || (!!model.is_locked && model.user_id!==user.id) || (isFieldReadable && !model.accounting_update && !!receiptId)) || (accessRight.length < 2 && accessRight.includes('show'))"
              @click="saveReceipt"
            >
              {{ $t('receipt.save') }}
            </v-btn>
          </v-card-title>
          <v-divider/>
          <v-card-text class="text-h5 pa-2">
            <FormSkeleton v-if="receiptId && Object.keys(receiptById).length <= 0"/>
            <v-form
              v-else
              ref="form"
              v-model="valid"
              lazy-validation
              :disabled="(accessRight.length < 2 && accessRight.includes('show'))"
            >
              <Alert :alert.sync="alert" :message="message" />
              <v-row>
                <v-col cols="6">
                  <v-card
                    class="mx-auto"
                    flat
                  >
                    <v-card-title class="py-1 primary--text">
                      <v-icon color="primary">mdi-account-group-outline</v-icon>
                      <h6 class="ms-2 font-weight-regular">{{$t('receipt.customerDetail')}}</h6>
                      <v-spacer />
                      <v-icon v-if="customer_id" @click="openCustomerAdditionalDetailModel" color="primary" class="float-end">mdi-eye-outline</v-icon>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="12">
                          <v-combobox
                            class="asterisk"
                            autocomplete="new-password"
                            dense
                            :readonly="isFieldReadable ? true : false"
                            cache-items
                            v-model="selectedCustomer"
                            :items="customerList.data"
                            item-text="name_1"
                            item-value="id"
                            @change="onCustomerChange"
                            flat
                            :rules="customerValid"
                            return-object
                            hide-no-data
                            hide-details="auto"
                            required
                            single-line
                            @focus="documentNextAndPrevious(originModel.customer_id)"
                            @blur="documentNextAndPreviousBlur()"
                            @dblclick="redirectOnEditCustomerPage"
                            :search-input.sync="customerSearch"
                            :label="$t('receipt.field.customer')"
                          >
                            <template v-slot:append-outer>
                              <v-icon color="primary" dense v-if="!isFieldReadable" @click="customerDialog = true">
                                mdi-magnify
                              </v-icon>
                            </template>
                            <template v-slot:selection="data">
                              <span @dblclick="redirectOnEditCustomerPage">{{ `${data.item.id}. ${data.item.name_1}` }}</span>
                            </template>
                            <template v-slot:item="data">
                              <div class="font-weight-medium" style="font-size: .8125rem;display: flex;align-item: center;">
                                {{ `${data.item.id}. ${data.item.name_1}` }}
                              </div>
                            </template>
                            <template v-slot:append-item>
                              <div v-show="hasNextPage" v-intersect="infiniteScroll" ref="load" class="loader text-center">
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                />
                              </div>
                            </template>
                          </v-combobox>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            class="asterisk"
                            autocomplete="new-password"
                            hide-details="auto"
                            :readonly="isFieldReadable"
                            v-model="model.customer_name"
                            :label="$t('receipt.field.customerName')"
                            :rules="customerNameValid"
                            required
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            :readonly="isFieldReadable"
                            hide-details="auto"
                            v-model="model.address"
                            :label="$t('receipt.field.address')"
                            :rules="[lessThanValidation($t('receipt.field.address'), $t('common.less'), 250, model.address)]"
                            required
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            :readonly="isFieldReadable"
                            hide-details="auto"
                            v-model="model.town"
                            :label="$t('receipt.field.town')"
                            :rules="[lessThanValidation($t('receipt.field.town'), $t('common.less'), 100, model.town)]"
                            required
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card
                    class="mx-auto"
                    flat
                  >
                    <v-card-title class="py-1 primary--text">
                      <v-icon color="primary">mdi-file-delimited-outline</v-icon>
                      <h6 class="ms-2 font-weight-regular">{{$t('receipt.receiptDetail')}}</h6>
                      <v-spacer />
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="4">
                          <v-text-field
                            dense
                            hide-details="auto"
                            v-model="model.receipt_year"
                            readonly
                            :rules="receiptYearValid"
                            required
                            :label="$t('receipt.field.year')"
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-menu
                            v-if="!isFieldReadable"
                            ref="startDateMenu"
                            v-model="startDateMenu"
                            :close-on-content-click="false"
                            :return-value.sync="model.receipt_date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                dense
                                hide-details="auto"
                                v-model="formattedReceiptDate"
                                :label="$t('receipt.field.receiptDate')"
                                v-bind="attrs"
                                :rules="profileById.date_format ? dateValid : dateValid2"
                                v-on="on"
                                readonly
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="model.receipt_date"
                              :min="minDate"
                              :max="maxDate"
                              @change="$refs.startDateMenu.save(model.receipt_date)"
                            >
                              <v-btn small class="primary" @click="$refs.startDateMenu.save(todayDate())">{{ $t('route.today') }}</v-btn>
                            </v-date-picker>
                          </v-menu>
                          <v-text-field
                            v-else
                            dense
                            hide-details="auto"
                            v-model="formattedReceiptDate"
                            :label="$t('receipt.field.receiptDate')"
                            readonly
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-combobox
                            autocomplete="new-password"
                            :readonly="isFieldReadable"
                            v-model="selectedEmployee"
                            :items="employeeList.data"
                            item-text="name"
                            item-value="id"
                            @change="onEmployeeChange"
                            :clearable="!isFieldReadable"
                            flat
                            return-object
                            hide-no-data
                            hide-details="auto"
                            dense
                            required
                            single-line
                            @dblclick="redirectOnEditEmployeePage"
                            :search-input.sync="employeeSearch"
                            :label="$t('receipt.field.agent')"
                          >
                            <template v-slot:append-outer>
                              <v-icon class="mt-1" color="primary" dense v-if="!isFieldReadable" @click="employeeDialog = true">
                                mdi-magnify
                              </v-icon>
                            </template>
                            <template v-slot:item="data">
                              <div class="font-weight-medium" style="font-size: .8125rem;display: flex;align-item: center;">
                                {{ `${data.item.id}. ${data.item.name}` }}
                              </div>
                            </template>
                            <template v-slot:append-item>
                              <div v-show="hasEmployeeNextPage" v-intersect="infiniteScrollOnEmployee" ref="load" class="loader text-center">
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                />
                              </div>
                            </template>
                          </v-combobox>
                        </v-col>
                      </v-row>
                      <v-row :dense="true">
                        <v-col cols="4" v-if="moreDocumentNumber && !receiptId">
                          <v-autocomplete
                            :disabled="isReceiptId"
                            hide-details="auto"
                            cache-items
                            :items="documentData"
                            :key="documentData.length"
                            item-text="from_no"
                            item-value="id"
                            flat
                            :rules="!isReceiptId ? documentNumberRequired : []"
                            v-model="model.doc_series_number"
                            return-object
                            hide-no-data
                            required
                            single-line
                            :label="$t('documentSeries.selectNumber')"
                          >
                            <template v-slot:item="data">
                              <span>{{`${data.item.series} - ${data.item.from_no}. ${data.item.description}`}}</span>
                            </template>
                            <template v-slot:selection="data">
                              <span>{{`${data.item.series} - ${data.item.from_no}. ${data.item.description}`}}</span>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card
                    class="mx-auto"
                    flat
                  >
                    <v-card-title class="py-1 primary--text">
                      <v-row dense no-gutters>
                        <v-col cols="8" class="d-flex">
                          <v-icon color="primary">mdi-cart-plus</v-icon>
                          <h6 class="ms-2 align-self-center font-weight-regular">{{$t('receipt.listOfPayment')}}</h6>
                        </v-col>
                        <v-col cols="4" class="text-end font-weight-regular">
                          <v-btn
                            v-if="receiptId"
                            class="me-2"
                            @click="getInvoices('updatedDocuments')"
                            color="primary"
                            outlined
                            small
                          >
                            {{ $t('receipt.updatedDocuments') }}
                          </v-btn>
                          <v-btn
                            @click="getInvoices(null)"
                            color="primary"
                            outlined
                            small
                            :disabled="!valid || isFieldReadable || isReceiptId" 
                          >
                            {{ $t('receipt.getInvoices') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <v-row :dense="true">
                        <v-col cols="12">
                          <v-simple-table
                            dense
                            class="custom-table elevation-1 item-table"
                            fixed-header
                            height="250"
                          >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th :style="{width: '40px'}">
                                    {{ $t('receipt.no') }}
                                  </th>
                                  <th :style="{width: '120px'}">
                                    {{ $t('receipt.field.paymentDate') }}
                                  </th>
                                  <th :style="{width: '140px'}">
                                    {{ $t('receipt.paymentType') }}
                                  </th>
                                  <th :style="{width: '140px'}">
                                    {{ $t('receipt.chequeNumber') }}
                                  </th>
                                  <th :style="{width: '140px'}">
                                    {{ $t('receipt.bankNo') }}
                                  </th>
                                  <th :style="{width: '140px'}">
                                    {{ $t('receipt.branch') }}
                                  </th>
                                  <th :style="{width: '110px'}">
                                    {{ $t('receipt.accountNo') }}
                                  </th>
                                  <th v-if="!receiptId || (receiptId && accessRight.includes('financial'))" :style="{width: '110px'}">
                                    {{ $t('receipt.amount') }}
                                  </th>
                                  <th :style="{width: '110px'}">
                                    {{ $t('receipt.field.remarks') }}
                                  </th>
                                  <th class="text-center" :style="{width: '100px'}">
                                    {{ $t('receipt.action') }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr style="vertical-align: bottom" v-for="(item, index) in paymentItems" :key="index" :class="index+1 == paymentItems.length ? 'newRow' : ''">
                                  <td>
                                    {{index+1}}
                                  </td>
                                  <td>
                                    <v-menu
                                      v-if="!isFieldReadable"
                                      :ref="`startDateMenu${index}`"
                                      v-model="item.startDateMenu"
                                      :close-on-content-click="false"
                                      :return-value.sync="item.payment_date"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="auto"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                          class="date-input"
                                          hide-details="auto"
                                          dense
                                          v-model="processedPaymentItems[index].formattedPaymentDate"
                                          :rules="paymentDateValid"
                                          required
                                          v-bind="attrs"
                                          v-on="on"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="item.payment_date"
                                        @change="$refs[`startDateMenu${index}`][0].save(item.payment_date)"
                                      >
                                        <v-btn small class="primary" @click="$refs[`startDateMenu${index}`][0].save(todayDate())">{{ $t('route.today') }}</v-btn>
                                      </v-date-picker>
                                    </v-menu>
                                    <v-text-field
                                      v-else
                                      class="date-input"
                                      dense
                                      hide-details="auto"
                                      v-model="processedPaymentItems[index].formattedPaymentDate"
                                      readonly
                                    />
                                  </td>
                                  <td>
                                    <v-select
                                      :readonly="isFieldReadable"
                                      dense
                                      hide-details="auto"
                                      v-model="item.payment_terms_id"
                                      :items="paymentGroupValue"
                                      item-text="description"
                                      item-value="id"
                                      return-object
                                      :rules="paymentTypeValid"
                                      required
                                      :menu-props="{ bottom: true, offsetY: true }"
                                      @change="paymentTypeChange(item, index)"
                                    >
                                      <template v-slot:append-outer>
                                        <v-icon color="primary" dense v-if="item.payment_terms_id && !isFieldReadable && (item.payment_terms_id.description === 'Bank transfer' || item.payment_terms_id.description === 'העברה')" @click="item.payment_terms_id ? item.payment_terms_id.description === 'Bank transfer' || item.payment_terms_id.description === 'העברה' ? openBankDialog(index) : '' : ''">
                                          mdi-bank
                                        </v-icon>
                                      </template>
                                    </v-select>
                                  </td>
                                  <td>
                                    <v-text-field
                                      :ref="'chequeRef'+index"
                                      dense
                                      :readonly="isFieldReadable"
                                      hide-details="auto"
                                      v-model="item.cheque_no"
                                      :rules="paymentTypeArray.includes(item.payment_terms_id ? item.payment_terms_id.description : '') ? chequeNumberValid : []"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      :ref="'bankRef'+index"
                                      dense
                                      :readonly="isFieldReadable"
                                      hide-details="auto"
                                      v-model="item.bank"
                                      :rules="paymentTypeArray.includes(item.payment_terms_id ? item.payment_terms_id.description : '') ?bankNoValid : []"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      :ref="'branchRef'+index"
                                      dense
                                      :readonly="isFieldReadable"
                                      hide-details="auto"
                                      v-model="item.branch"
                                      :rules="paymentTypeArray.includes(item.payment_terms_id ? item.payment_terms_id.description : '') ?branchValid : []"
                                      required
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      :ref="'accountNoRef'+index"
                                      dense
                                      :readonly="isFieldReadable"
                                      hide-details="auto"
                                      v-model="item.account_no"
                                      :rules="paymentTypeArray.includes(item.payment_terms_id ? item.payment_terms_id.description : '') ?accountNoValid : []"
                                      required
                                    />
                                  </td>
                                  <td v-if="!receiptId || (receiptId && accessRight.includes('financial'))">
                                    <v-text-field
                                      dense
                                      :name="'amount'+index"
                                      :ref="'amount'+index"
                                      :readonly="isFieldReadable"
                                      :reverse="locale === 'he' ? false : true"
                                      hide-details="auto"
                                      v-model="item.amount"
                                      :rules="amountValid"
                                      required
                                      @input="itemPriceCalculation(index)"
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      dense
                                      :readonly="isFieldReadable"
                                      hide-details="auto"
                                      v-model="item.remarks"
                                      :title="item.remarks"
                                      :rules="[lessThanValidation($t('receipt.field.remarks'), $t('common.less'), 25, item.remarks)]"
                                      required
                                    />
                                  </td>
                                  <td class="text-center">
                                    <v-row>
                                      <v-col cols="4" class="text-end">
                                        <v-btn
                                          icon
                                          :disabled="isFieldReadable || (accessRight.length < 2 && accessRight.includes('show'))"
                                          color="primary"
                                          small
                                          @click="addRow"
                                        >
                                          <v-icon color="primary">
                                            mdi-plus
                                          </v-icon>
                                        </v-btn>
                                      </v-col>
                                      <v-col cols="4" class="text-end">	
                                        <v-btn	
                                          icon	
                                          :disabled="isFieldReadable || (accessRight.length < 2 && accessRight.includes('show')) || !item.payment_terms_id"	
                                          color="primary"	
                                          x-small	
                                          class="mt-1"	
                                          @click="copyData(index)"	
                                        >	
                                          <v-icon color="primary">	
                                            mdi-content-copy	
                                          </v-icon>	
                                        </v-btn>	
                                      </v-col>
                                      <v-col cols="4" class="text-start">
                                        <v-btn
                                          v-if="paymentItems.length !== 1"
                                          icon
                                          :disabled="isFieldReadable || (accessRight.length < 2 && accessRight.includes('show'))"
                                          color="error"
                                          small
                                          @click="deleteRow(index)"
                                        >
                                          <v-icon color="error">
                                            mdi-delete-sweep-outline
                                          </v-icon>
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                      <v-row :dense="true" class="pt-6">
                        <v-col cols="8">
                          <v-row :dense="true">
                            <v-col cols="8">
                              <v-row>
                                <v-col cols="6">
                                  <v-radio-group hide-no-data hide-details="auto" dense single-line v-model="selectedStatus" :disabled="isFieldReadable" readonly class="text-caption pt-0 mt-3">
                                    <template v-slot:label>
                                      <div>{{$t('receipt.field.status')}}</div>
                                    </template>
                                    <v-radio
                                      v-for="statusItem in statusList"
                                      :key="statusItem.id"
                                      :label="`${statusItem.name}`"
                                      :value="statusItem.id"
                                    ></v-radio>
                                  </v-radio-group>
                                  <v-select
                                    class="my-5"
                                    v-model="selectedGroup"
                                    :items="groupValue"
                                    item-text="description"
                                    item-value="id"
                                    return-object
                                    :menu-props="{ bottom: true, offsetY: true }"
                                    :label="$t('receipt.receiptStatus')"
                                    @change="changeCategoryStatus"
                                  />
                                </v-col>
                                <v-col cols="6">
                                  <v-checkbox
                                    :dense="true"
                                    v-model="model.accounting_update"
                                    :readonly="!receiptId || originModel.status == 0 && !receiptId || (!!receiptId && originModel.status == 0 && originModel.customer && originModel.customer.book_kiping_no && !!originModel.accounting_update) || (originModel.customer && originModel.customer.book_kiping_no && originModel.accounting_update == 1)"
                                    hide-details="auto"
                                    @click="bookkeepingConfirmationDialog = !!receiptId && originModel.status == 0 && originModel.customer && !originModel.customer.book_kiping_no && !originModel.accounting_update ? true : false"
                                    @click.native.stop="bookkeepingRecordsDialog = originModel.accounting_update && originModel.status == 1 || originModel.status == 2 ? true : false"
                                    :label="$t('receipt.field.accountUpdate')"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="4">
                              <v-textarea
                                :readonly="isFieldReadable"
                                hide-details="auto"
                                dense
                                rows="7"
                                v-model="model.remarks"
                                :label="$t('receipt.field.remarks')"
                                :rules="[lessThanValidation($t('receipt.field.remarks'), $t('common.less'), 250, model.remarks)]"
                              />
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-divider class="mx-8" vertical/>
                        <v-col cols="3" v-if="!receiptId || (receiptId && accessRight.includes('financial'))">
                          <v-row dense>
                            <v-col cols="6" class="font-weight-bold">
                              {{$t('receipt.total')}}
                            </v-col>
                            <v-col cols="6" class="font-weight-bold" :class="locale === 'he' ? 'text-start' : 'text-end'">
                              {{numberWithCommas(model.total)}}
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6">
                              {{$t('receipt.field.discount')}}
                            </v-col>
                            <v-col cols="6">
                              <v-text-field
                                dense
                                height="18"
                                class="ma-0 pa-0"
                                hide-details="auto"
                                name="tax"
                                ref="tax"
                                :readonly="isFieldReadable"
                                :reverse="locale === 'he' ? false : true"
                                v-model="model.with_holding_tax"
                                :rules="discountValid"
                                required
                                @input="receiptPriceCalculation"
                              />
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col cols="6" class="font-weight-bold">
                              {{$t('receipt.field.grandTotal')}}
                            </v-col>
                            <v-col cols="6" class="font-weight-bold" :class="locale === 'he' ? 'text-start' : 'text-end'">
                              {{numberWithCommas(model.grand_total)}}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-overlay :z-index="999" v-if="isProgressCircular">
        <v-icon size="64" class="hourglass-icon" color="primary">mdi-timer-sand-paused</v-icon>
      </v-overlay>
      <ActivityLogTableDialog
        :actDialog.sync="actDialog"
        :docId.sync="receiptId"
        docType="Receipt"
        docMode="sales"
        docItems="ReceiptPayment"
        docEmailSent="ReceiptEmailSent"
      />
      <BookkeepingRecordsDialog :bookkeepingRecordsDialog.sync="bookkeepingRecordsDialog" type="Receipt" :docId.sync="originModel.recipt_document_number" docMode="sales" />
      <CustomerDetail :model.sync="customerAdditionalDetail" :isFieldReadable="isFieldReadable" @saveCustomerAdditionalDetail="saveCustomerAdditionalDetail" :customerDetailDialog.sync="customerDetailDialog"/>
      <EmployeeDialog @onEmployeeChange="onEmployeeChange" :dialog.sync="employeeDialog" />
      <CustomerDialog @onCustomerChange="onCustomerChange" :dialog.sync="customerDialog" />
      <BankDialog @onBankChange="onBankChange" :dialog.sync="bankDialog" />
      <BookkeepingConfirmationDialog :dialog.sync="bookkeepingConfirmationDialog" :bookkeepingConfirmation="bookkeepingConfirmation" />
      <!-- dialog for select invoice -->
      <receipt-invoices :receiptInvoicesDialog.sync="receiptInvoicesDialog" :receiptId="receiptId" :receiptInvoicesModel.sync="receiptInvoicesModel" :customerId="customer_id" :grandTotal="model.grand_total" :paidTotal.sync="paidTotal" :receiptInvoicesType.sync="receiptInvoicesType" :updatedButton="type" :updatedDocuments="updatedDocuments" :accessRight="accessRight" />
      <RemarksDialog :dialog.sync="remarksDialog" :status="originModel.status" :docId="receiptId" :docType="24" />
      <confirmation-dialog :dialog.sync="confirmationDialogStatus" :delete_item.sync="delete_item" @deleteItem="deleteItem"/>
      <AttachedDocumentsDialog :docId="receiptId" docType="Receipt" :dialog.sync="attachedDocumentsDialog" />
      <PeriodClosureConfirmationDialog :dialog.sync="periodClosureConfirmationDialog" :periodClosureConfirmation="periodClosureConfirmation" />
      <ReceiptBookkeepingDialog v-if="receiptBookkeepingDialog" :dialog.sync="receiptBookkeepingDialog" :customer="selectedCustomer"/>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import {getAuxiliaryZtableValueByName, changedFormData} from '@/utils';
import FormSkeleton from '@/components/skeleton/FormSkeleton';
import Alert from "@/components/Alert";
import CustomerDialog from "@/components/moduleTableDialog/Dialog/CustomerDialog";
import BankDialog from '@/components/moduleTableDialog/Dialog/BankDialog';
import ReceiptInvoices from './Dialog/ReceiptInvoices';
import ActivityLogTableDialog from '@/components/activityLog/Dialogs/ActivityLogTableDialog.vue';
import BookkeepingRecordsDialog from '@/components/bookkeepingRecords/Dialogs/BookkeepingRecordsDialog.vue';
import CustomerDetail from "@/components/moduleTableDialog/Dialog/CustomerDetail";
import EmployeeDialog from "@/components/moduleTableDialog/Dialog/EmployeeDialog";
import HtmlToPdf from "@/components/generatePDF/HtmlToPdf";
import { formatDateDDMMYYYY, parseDateYYYYMMDD, todayDate } from '../../../utils';
import RemarksDialog from "@/components/RemarksDialog"
import BookkeepingConfirmationDialog from '@/components/BookkeepingConfirmationDialog';
import ConfirmationDialog from '@/components/ConfirmationDialog';
import AttachedDocumentsDialog from "@/components/AttachedDocumentsDialog";
import DocumentNumberConfirmation from "@/components/DocumentNumberConfirmation";
import PeriodClosureConfirmationDialog from '@/components/PeriodClosureConfirmationDialog';
import ReceiptBookkeepingDialog from '@/components/ReceiptBookkeepingDialog';
import NextAndPreviousCommon from "@/components/NextAndPreviousCommon";
import LoginGoogle from '@/components/LoginGoogle';
import CommonCalendar from '@/components/CommonCalendar';
import { validationMixin } from '@/mixins/validationMixin';
import { intersectionMixin } from '@/mixins/intersectionMixin';
import { searchMixin } from '@/mixins/searchMixin';

export default {
  name: 'ReceiptAction',
  components: {
    FormSkeleton,
    Alert,
    CustomerDetail,
    ActivityLogTableDialog,
    BookkeepingRecordsDialog,
    EmployeeDialog,
    CustomerDialog,
    BookkeepingConfirmationDialog,
    ReceiptInvoices,
    BankDialog,
    HtmlToPdf,
    RemarksDialog,
    ConfirmationDialog,
    AttachedDocumentsDialog,
    DocumentNumberConfirmation,
    PeriodClosureConfirmationDialog,
    ReceiptBookkeepingDialog,
    NextAndPreviousCommon,
    LoginGoogle,
    CommonCalendar
  },
  mixins: [validationMixin, intersectionMixin, searchMixin],
  data() {
    return {
      message: '',
      customerDetailDialog: false,
      bookkeepingConfirmationDialog: false,
      bookkeepingRecordsDialog: false,
      alert: false,
      loading: false,
      valid: false,
      confirmationDialog: false,
      customerAdditionalDetail: {},
      model: {
        status: 0,
        total: 0.00,
        discount: 0.00,
        after_discount: 0.00,
        vat: 0.00,
        grand_total: 0.00,
        receipt_date:'',
      },
      formattedReceiptDate:'',
      originModel: {},
      selectedCustomer: null,
      customerDialog: false,
      selectedEmployee: null,
      employeeDialog: false,
      actDialog: false,
      startDateMenu: false,
      paymentItems: [{
        bank: null,
        branch: null,
        account_no: null,
        amount: null,
        payment_date: new Date().toISOString().substring(0,10)
      }],
      processedPaymentItems:[{formattedPaymentDate:'',payment_date:''}],
      paymentGroupValue: [],
      customer_id: null,
      customerPage: 1,
      customerLimit: 10,
      employeePage: 1,
      employeeLimit: 10,
      dialog: false,
      selectedStatus: 0,
      receiptInvoicesModel: null,
      receiptInvoicesDialog: false,
      paidTotal: null,
      receiptInvoicesType: null,
      updatedDocuments: null,
      bankDialog: false,
      bankDialogIndex: null,
      nextId: null,
      prevId: null,
      remarksDialog: false,
      paymentTypeArray: ['Cheque', 'המחאה'],
      cashPaymentArray: ['Cash', 'מזומן'],
      confirmationDialogStatus: false,
      delete_item: null,
      type: null,
      groupValue: [],
      selectedGroup: null,
      attachedDocumentsDialog: false,
      saveOnlyCategoryStatus: false,
      confirmDocumentNumberDialog: false,
      moreDocumentNumber: false,
      documentData: [],
      periodClosureConfirmationDialog: false,
      isProgressCircular: false,
      receiptBookkeepingDialog: false,
    }
  },
  computed: {
    statusList() {
      return [
        { name: this.$t('receipt.field.open'), id: 0 },
        { name: this.$t('receipt.field.finished'), id: 1 },
        { name: this.$t('receipt.field.canceled'), id: 2 }
      ]
    },
    isReceiptId() {
      return this.receiptId ? true : false;
    },
    receiptYearValid() {
      return [
        v => !!v || this.$t('formRules.receiptYearRequired'),
      ];
    },
    paymentDateValid() {
      return [
        v => !!v || this.$t('formRules.paymentDateRequired'),
        v => !/^\s*$/.test(v) || this.$t('formRules.paymentDateRequired')
      ];
    },
    paymentTypeValid() {
      return [
        v => !!v || this.$t('formRules.paymentTypeRequired'),
        v => !/^\s*$/.test(v) || this.$t('formRules.paymentTypeRequired')
      ];
    },
    chequeNumberValid() {
      return [
        v => !!v || this.$t('formRules.chequeNumberRequired'),
        v => !v || (v && v.length <= 20) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('receipt.chequeNumber'), type: this.$t('common.less'), size: 20 }),
        v => !v || /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/.test(v) || this.$t('formRules.spaceValidValue'),
      ];
    },
    bankNoValid() {
      return [
        v => !!v || this.$t('formRules.bankNoRequired'),
        v => !v || (v && v.length <= 20) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('receipt.bankNo'), type: this.$t('common.less'), size: 20 }),
      ];
    },
    branchValid() {
      return [
        v => !!v || this.$t('formRules.branchRequired'),
        v => !v || (v && v.length <= 20) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('receipt.branch'), type: this.$t('common.less'), size: 20 }),
      ];
    },
    accountNoValid() {
      return [
        v => !!v || this.$t('formRules.accountNoRequired'),
        v => !v || (v && v.length <= 25) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('receipt.accountNo'), type: this.$t('common.less'), size: 25 }),
      ];
    },
    discountValid() {
      return [
        v => !v || /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/.test(v) || this.$t('formRules.spaceValidValue'),
        v => !v || (v && String(v).length <= 12) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('quotation.field.discount'), type: this.$t('common.less'), size: 12 }),
      ];
    },
    amountValid() {
      return [
        v => !!v || this.$t('formRules.amountRequired'),
        v => !/^\s*$/.test(v) || this.$t('formRules.amountRequired'),
        v => !v || /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/.test(v) || this.$t('formRules.spaceValidValue'),
        v => !v || (v && String(v).length <= 12) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('receipt.amount'), type: this.$t('common.less'), size: 12 }),
      ];
    },
    receiptId() {
      return this.$route.params.receipt_id
    },
    ...mapGetters({
      receiptById: 'receipt/receiptById',
      locale: 'locale',
      profileById: 'profile/profileById',
      employeeById: 'employee/employeeById',
      customerList: 'customer/customerList',
      remarksList: 'remarks/remarksList',
      employeeList: 'employee/employeeList',
      receiptQuery: 'receipt/receiptQuery',
      accessRight: 'accessRight',
      user: 'user',
      moreThanOneUserLogout: 'moreThanOneUserLogout',
      bookkeepingConfirmation: 'bookkeepingConfirmation',
      periodClosureConfirmation: 'multiple/periodClosureConfirmation'
    }),
    isFieldReadable () {
      return this.model.status == 0 ? false : true;
    },
    minDate() {
      if (this.receiptId) {
        const date = parseDateYYYYMMDD(this.model.receipt_date)?.split('-')[0]
        return `${date}-01-01`
      } else {
        return null;
      }
    },
    maxDate() {
      if (this.receiptId) {
        const date = parseDateYYYYMMDD(this.model.receipt_date)?.split('-')[0]
        return `${date}-12-31`
      } else {
        return null;
      }
    },
  },
  watch: {
    paymentItems:{
      handler(val){
      this.processedPaymentItems = val.map(e=>({...e,formattedPaymentDate:this.profileById.date_format ? formatDateDDMMYYYY(e.payment_date) :e.payment_date}))
    },
    deep:true
    },
    periodClosureConfirmation: {
      handler: function(val) {
        if (val) {
          this.periodClosureConfirmationDialog = true;
        }
      },
      deep: true
    },
    'model.receipt_date'(val){
      const year = this.model.receipt_year
      this.formattedReceiptDate = this.profileById.date_format ? formatDateDDMMYYYY(val): val;
      this.model.receipt_year = parseDateYYYYMMDD(val).split('-')[0]
      if (year && (this.model.receipt_year !== year)) {
        this.documentSeriesFunction();
      }
    },
    customerLimit(newVal, oldVal) {
      if(newVal !== oldVal)
        this.$store.dispatch('customer/GetCustomer', {page: 1, limit: this.customerLimit, order_by: 'name_1|asc'});
    },
    employeeLimit(newVal, oldVal) {
      if(newVal !== oldVal)
        this.$store.dispatch('employee/GetEmployee', {page: 1, limit: this.employeeLimit, order_by: 'name|asc'});
    },
    bookkeepingConfirmation: {
      handler: function(val) {
        if (val) {
          this.bookkeepingConfirmationDialog = true;
        }
      },
      deep: true
    },
    receiptById() {
      if (this.$route.params.receipt_id) {
        if (Object.keys(this.receiptById).length > 0) {
          this.model = Object.assign({}, this.model, this.receiptById)
          if(this.model.receipt_date) {
            this.model.receipt_date = new Date(this.model.receipt_date).toISOString().substring(0,10);
          }
          this.selectedStatus = this.model.status;
          this.originModel = Object.assign({}, this.originModel, this.model);
        }
      } else if (this.$route.params.copiedModel) {
        this.model = Object.assign({}, this.model, this.receiptById)
        delete this.model.created_at
        delete this.model.id;
        delete this.model.is_printed;
        delete this.model.updated_at;
        delete this.model.accounting_update;
        this.model.receipt_year = new Date().getFullYear();
        this.model.receipt_date = new Date().toISOString().substring(0,10);
      }
    },
    receiptInvoicesModel() {
      this.model = Object.assign({}, {...this.model, invoiceData: this.receiptInvoicesModel?.invoiceData});
    },
    profileById(){
      this.processedPaymentItems = this.paymentItems.map(e=>({...e,formattedPaymentDate:this.profileById.date_format ?formatDateDDMMYYYY(e.payment_date) :e.payment_date}))
      this.formattedReceiptDate = this.profileById.date_format ? formatDateDDMMYYYY(this.model.receipt_date): this.model.receipt_date;
    },
    async moreThanOneUserLogout() {
      if (this.moreThanOneUserLogout && this.model.id) {
        await this.$store.dispatch('PostDocumentOpenAction',{table:'Receipt',id:this.model.id,is_locked:false});
        this.$store.dispatch('LogOut');
        this.$router.push({ name: 'login' });
      }
    }
  },
  async created() {
    window.addEventListener('beforeunload', this.handler)
  },
  async mounted() {
    this.onMounteUpdated();
    if (this.receiptId) {
      this.$store.dispatch('remarks/GetRemarks', {where: 'document_type|'+24, where_and: 'document_id|'+this.receiptId})
    }
    this.selectedEmployee = this.user.name
  },
  beforeDestroy() {
    if (this.model.id && !this.moreThanOneUserLogout && this.model.is_locked && this.model.user_id == this.user.id || this.model.id && !this.model.user_id && !this.moreThanOneUserLogout) {
      this.$store.dispatch('PostDocumentOpenAction',{table:'Receipt',id:this.model.id,is_locked:false});
    }
    this.$refs.form ? this.$refs.form.reset() : '';
    this.model = {};
    this.customer_id = null;
    this.dialog = false;
    this.$store.commit('customer/SET_CUSTOMER_BY_ID', {});
    this.$store.commit('receipt/SET_RECEIPT_BY_ID', {});
    this.$store.commit('customer/SET_CUSTOMER', []);
    this.$store.commit('SET_USER_LOGOUT', false)
    window.removeEventListener('beforeunload', this.handler)
  },
  methods: {
    todayDate: todayDate,
    copyReceipt() {
      let copyModel = this.$route.params.receipt_id;
      this.$router.push({
        name: 'addReceipt',
        params: {
          copiedModel: copyModel,
        },
      })
    },
    async documentSeriesFunction() {
      this.documentData = []
      await this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|Receipt,${this.model.receipt_year}`).then((res) => {
        if (res.length > 1) {
          this.moreDocumentNumber = true;
          this.documentData = res;
        } else {
          this.moreDocumentNumber = false;
        }}
      )
      await this.$store.dispatch('documentSeries/GetDocumentSeries', `?where_and=type,year|Receipt,${this.model.receipt_year}`).then((res) => {
        if (res.length > 0) {
          this.confirmDocumentNumberDialog = false;
        } else {
          if (this.receiptId) {
            this.confirmDocumentNumberDialog = false;
          } else {
            this.confirmDocumentNumberDialog = true;
          }
        }
      })
    },
    async handler(event) {
      if (this.model.id && !this.moreThanOneUserLogout && this.model.is_locked && this.model.user_id == this.user.id || this.model.id && !this.model.user_id && !this.moreThanOneUserLogout) {
        await this.$store.dispatch('PostDocumentOpenAction',{table:'Receipt',id:this.model.id,is_locked:false});
      }
      event.preventDefault();
    },
    async onMounteUpdated() {
      if (this.$route.params.receipt_id || this.$route.params.copiedModel) {
        if (this.$route.params.receipt_id) {
          this.documentNextAndPrevious();
          await this.$store.dispatch('receipt/GetReceiptById', this.$route.params.receipt_id).then((res) => {
            if (res.recipt_document_number) {
              document.title = document.title + '   ' + res.recipt_document_number
            }
          });
          this.selectedGroup = this.model.status_one;
          if (!this.model.is_locked && this.$route.params.receipt_id) {
            await this.$store.dispatch('PostDocumentOpenAction',{table:'Receipt',id:this.$route.params.receipt_id,is_locked:true});
          }
        } else if (this.$route.params.copiedModel) {
          // Copy invoice
          await this.$store.dispatch('receipt/GetReceiptById', this.$route.params.copiedModel);
          this.model.status = 0;
          this.selectedGroup = null;
          this.model.status_one = null;
        }
        this.customer_id = this.model?.customer_id;
        this.selectedCustomer = {id: this.model?.customer_id, name_1: this.model.customer?.name_1, book_kiping_no: this.model.customer?.book_kiping_no};
        if (this.selectedCustomer && !this.selectedCustomer?.book_kiping_no && !this.receiptId) {
          this.receiptBookkeepingDialog = true
        }
        if (this.model.employee_id) {
          this.selectedEmployee = {id: this.model?.employee?.id, name: this.model?.employee?.name};
          this.model.employee_id = this.model?.employee_id;
        }
        this.model.with_holding_tax = this.numberWithCommas(this.model.with_holding_tax, 'noFloat');
        // payment detail
        this.paymentItems = [];
        this.model.receiptPayment.forEach((element) => {
          delete element['created_at'];
          delete element['updated_at'];
          if(this.$route.params.copiedModel) {
            delete element['id'];
            delete element['receipt_id'];
          }
          let amount = this.numberWithCommas(element['amount'], 'noFloat');
          this.paymentItems.push(Object.assign({}, {...element, amount: amount}))
        })
        // updated documents detail
        if (this.model.receiptInvoices.length > 0) {
          this.updatedDocuments = this.model.receiptInvoices;
        }
      } else {
        if (this.$route.query.customerId) {
          let customer = await this.$store.dispatch('customer/GetCustomerById', this.$route.query.customerId)
          let customerModel = {};
          this.selectedCustomer = {id: customer.id, name_1: customer.name_1, book_kiping_no: customer?.book_kiping_no};
          if (this.selectedCustomer && !this.selectedCustomer?.book_kiping_no && !this.receiptId) {
            this.receiptBookkeepingDialog = true
          }
          if (this.$route.query.employeeId) {
            await this.$store.dispatch('employee/GetEmployeeById', this.$route.query.employeeId);
            this.selectedEmployee = {id: this.employeeById.id, name: this.employeeById.name};
            this.model.employee_id = this.employeeById.id;
          }
          this.model.customer_id = customer.id;
          this.selectedGroup = null;
          this.model.status_one = null;
          this.customer_id = customer.id;
          let invoiceData = this.$route.query.invoiceData;
          this.model.customer_name = invoiceData.customer_name;
          customerModel.authorized_dealer_no = invoiceData.authorized_dealer_no;
          customerModel.email = invoiceData.email;
          customerModel.address = invoiceData.address;
          customerModel.town = invoiceData.town;
          customerModel.country = invoiceData.country;
          customerModel.phone_1 = invoiceData.phone_1;
          customerModel.phone_2 = invoiceData.phone_2;
          customerModel.cellular = invoiceData.cellular;
          customerModel.fax = invoiceData.fax;
          this.model = Object.assign({}, this.model, customerModel);
          this.model.status = 0;
          this.customerOrderNoRequired = customer?.customer_order_no_compulsory;
          if (this.model.status === 0) {
            this.$refs.form.validate()
          }
        }
        this.model.receipt_year = new Date().getFullYear();
        this.model.receipt_date = new Date().toISOString().substring(0,10);
      }
      this.$store.dispatch('profile/GetCompanyById');
      this.saveOnlyCategoryStatus = false;
      let tableNameValue = await getAuxiliaryZtableValueByName(8);
      if(Object.keys(tableNameValue).length > 0) {
        this.paymentGroupValue = tableNameValue.ztables.sort((a, b) => (a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()) ? 1 : -1);
      }
      let tableNameValue2 = await getAuxiliaryZtableValueByName(17);
      if (Object.keys(tableNameValue2).length > 0) {
        this.groupValue = tableNameValue2.ztables.sort((a, b) =>
          a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
            ? 1
            : -1
        );
      }
    },
    changeCategoryStatus() {
      if(this.originModel.status === 1) {
        this.saveOnlyCategoryStatus = true;
      }
    },
    async paymentTypeChange(item, index) {
      if (this.paymentTypeArray.includes(item.payment_terms_id.description)) {
        await this.$refs['bankRef'+index][0].onFocus()
        await this.$refs['branchRef'+index][0].onFocus()
        await this.$refs['accountNoRef'+index][0].onFocus()
        await this.$refs['chequeRef'+index][0].onFocus()
        await this.$refs['chequeRef'+index][0].onBlur()
      } else if (this.cashPaymentArray.includes(item.payment_terms_id.description)) {
        this.$refs['amount'+index][0].onFocus()
      }
    },
    callBackFunction() {
      this.$router.push({
        name: "Receipt",
        query: {
          ...this.receiptQuery,
        },
      });
    },
    async documentNextAndPrevious(customerId = null) {
      if (this.receiptId) {
        let docPagination = await this.$store.dispatch('GetDocumentPagination', {type: 'receipts', customer_id: customerId, document_id: Number(this.receiptId)});
        this.nextId = docPagination.nextId ? docPagination.nextId : null;
        this.prevId = docPagination.prevId ? docPagination.prevId : null;
      }
    },
    async documentNextAndPreviousBlur(customerId = null) {
      let self = this;
      setTimeout(async () => {
        if (self.receiptId) {
          let docPagination = await self.$store.dispatch('GetDocumentPagination', {type: 'receipts', customer_id: customerId, document_id: Number(self.receiptId)});
          self.nextId = docPagination.nextId ? docPagination.nextId : null;
          self.prevId = docPagination.prevId ? docPagination.prevId : null;
        }
      }, 1000);
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.customerLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async infiniteScrollOnEmployee([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.employeeLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    redirectOnNextAndPrevious(id) {
      if(id) {
        this.$router.push({ path: '/receipt/ReceiptAction/' + id})
      }
      else {return false}
    },
    redirectOnEditCustomerPage() {
      if(this.selectedCustomer){
        this.$router.push({ path: '/customer/CustomerAction/' + this.selectedCustomer.id})
      }
      else {return false;}
    },
    redirectOnEditEmployeePage() {
      if(this.selectedEmployee && this.selectedEmployee.id){
        this.$router.push({ path: '/employee/EmployeeAction/' + this.selectedEmployee.id})
      }
      else {return false;}
    },
    numberFromCommas(x) {
      return x ? x.toString().replace(/,/g, '') : null;
    },
    numberWithCommas(x, type = 'float') {
      return x ? type === 'float' ? parseFloat(x).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : type === 'float' ? (0).toFixed(2) : x;
    },
    onEmployeeChange(item) {
      if (item && typeof item === "object" && Object.keys(item).length > 0) {
        this.selectedEmployee = {id: item.id, name: item?.name};
        this.model.employee_id = item.id;
      } else {
        this.selectedEmployee = null;
        this.model.employee_id = null;
      }
    },
    openCustomerAdditionalDetailModel() {
      this.customerAdditionalDetail.authorized_dealer_no = this.model.authorized_dealer_no;
      this.customerAdditionalDetail.email = this.model.email;
      this.customerAdditionalDetail.country = this.model.country;
      this.customerAdditionalDetail.phone_1 = this.model.phone_1;
      this.customerAdditionalDetail.phone_2 = this.model.phone_2;
      this.customerAdditionalDetail.cellular = this.model.cellular;
      this.customerAdditionalDetail.fax = this.model.fax;
      this.customerDetailDialog = true;
    },
    async saveCustomerAdditionalDetail() {
      this.model.authorized_dealer_no = this.customerAdditionalDetail.authorized_dealer_no;
      this.model.email = this.customerAdditionalDetail.email;
      this.model.country = this.customerAdditionalDetail.country;
      this.model.phone_1 = this.customerAdditionalDetail.phone_1;
      this.model.phone_2 = this.customerAdditionalDetail.phone_2;
      this.model.cellular = this.customerAdditionalDetail.cellular;
      this.model.fax = this.customerAdditionalDetail.fax;
      if (this.$route.params.receipt_id) {
        this.isProgressCircular = true;
        await this.$store.dispatch('receipt/EditReceipt', {receipt_id: this.$route.params.receipt_id, receipt: {...this.customerAdditionalDetail}})
        this.isProgressCircular = false;
      }
    },
    lessThanValidation(fieldValue, typeValue, sizeValue, v) {
      return !v || (v && String(v).length <= sizeValue) || this.$t('formRules.lengthValidMessage', { fieldName: fieldValue, type: typeValue, size: sizeValue });
    },
    openBankDialog(index) {
      this.bankDialog = true;
      this.bankDialogIndex = index;
    },
    async onBankChange(bank) {
      if (bank && this.bankDialogIndex >= 0) {
        this.paymentItems[this.bankDialogIndex].bank = bank.name;
        this.paymentItems[this.bankDialogIndex].branch = bank.branch_name;
        this.paymentItems[this.bankDialogIndex].account_no = bank.account_no;
      }
    },
    async onCustomerChange(customer) {
      if (customer && typeof customer === "object" && Object.keys(customer).length > 0) {
        let customerModel = {};
        this.selectedCustomer = {id: customer.id, name_1: customer?.name_1, book_kiping_no: customer?.book_kiping_no};
        this.model.customer_id = customer.id;
        this.customer_id = customer.id;
        if (this.profileById.occasional_customer_no !== String(customer.id)) {
          this.model.customer_name = customer?.name_1;
          if (customer.employee_id) {
            customer.employee_id ? await this.$store.dispatch('employee/GetEmployeeById', customer.employee_id) : '';
            this.selectedEmployee = {id: this.employeeById.id, name: this.employeeById.name};
            this.model.employee_id = customer.employee_id;
          } else {
            // this.selectedEmployee = this.user.email.substring(0, this.user.email.indexOf("@"))
            this.selectedEmployee = this.user.name
          }
          customerModel.authorized_dealer_no = customer.authorized_dealer_no;
          customerModel.email = customer.email;
          customerModel.address = customer.address_1;
          customerModel.town = customer.town_1;
          customerModel.country = customer.country;
          customerModel.phone_1 = customer.phone_1;
          customerModel.phone_2 = customer.phone_2;
          customerModel.cellular = customer.cellular_1;
          customerModel.fax = customer.fax;
        } else {
          this.model.customer_name = '';
          customerModel.authorized_dealer_no = '';
          customerModel.email = '';
          customerModel.address = '';
          customerModel.town = '';
          customerModel.country = '';
          customerModel.phone_1 = '';
          customerModel.phone_2 = '';
          customerModel.cellular = '';
          customerModel.fax = '';
        }
        if (this.selectedCustomer && !this.selectedCustomer?.book_kiping_no) {
          this.receiptBookkeepingDialog = true
        }
        this.model = Object.assign({}, this.model, customerModel);
      }
    },
    itemPriceCalculation(index) {
      let item = this.paymentItems[index];
      let checkUnitPrice = item.amount;
      if (checkUnitPrice && this.$refs['amount'+index]) {
        const pos = this.$refs['amount'+index][0].$el.querySelector('input').selectionStart + 1;
        if(checkUnitPrice.length != this.numberWithCommas(this.numberFromCommas(item.amount), 'noFloat').length) {
          this.$nextTick(() => {
            this.$refs['amount'+index][0]?.$el ? this.$refs['amount'+index][0].$el.querySelector('input').selectionEnd = pos : '';
          })
        } else {
          this.$nextTick(() => {
            this.$refs['amount'+index][0]?.$el ? this.$refs['amount'+index][0].$el.querySelector('input').selectionEnd = pos-1 : '';
          })
        }
      }
      this.paymentItems[index].amount = this.numberWithCommas(this.numberFromCommas(item.amount), 'noFloat');
      this.$nextTick(() => {
        if(this.$refs.form && this.$refs['amount'+index][0].validate()) {
          item.amount = this.numberFromCommas(item.amount);
        }

        this.paymentItems[index].amount = this.numberWithCommas(this.numberFromCommas(item.amount), 'noFloat');
        this.paymentItems[index] = item;

        // total calculation on quotation
        this.receiptPriceCalculation();
      })
    },
    receiptPriceCalculation() {
      let self = this;
      this.model.total = this.paymentItems.length > 0 ? this.paymentItems.reduce(function(amount, item){
        return parseFloat(self.numberFromCommas(item.amount)) ? parseFloat(amount) + parseFloat(self.numberFromCommas(item.amount)) : amount + 0; 
      },0).toFixed(2) : 0;

      let checkWithHoldingTax = this.model.with_holding_tax;
      if (checkWithHoldingTax && this.$refs['tax']) {
        const pos = this.$refs['tax'].$el.querySelector('input').selectionStart + 1;
        if(checkWithHoldingTax.length != this.numberWithCommas(this.numberFromCommas(this.model.with_holding_tax), 'noFloat').length) {
          this.$nextTick(() => {
            this.$refs['tax']?.$el ? this.$refs['tax'].$el.querySelector('input').selectionEnd = pos : '';
          })
        } else {
          this.$nextTick(() => {
            this.$refs['tax']?.$el ? this.$refs['tax'].$el.querySelector('input').selectionEnd = pos-1 : '';
          })
        }
      }

      this.model.with_holding_tax = this.numberWithCommas(this.numberFromCommas(this.model.with_holding_tax), 'noFloat');

      this.$nextTick(() => {
        if(this.$refs.form && this.$refs['tax'].validate()) {
          this.model.with_holding_tax = this.numberFromCommas(this.model.with_holding_tax);
        }

        this.model.with_holding_tax = this.numberWithCommas(this.numberFromCommas(this.model.with_holding_tax), 'noFloat');
      })

      // grand total amount calculation
      this.model.grand_total = parseFloat(this.numberFromCommas(this.model.with_holding_tax)) ? (parseFloat(this.numberFromCommas(this.model.with_holding_tax)) + parseFloat(this.model.total)).toFixed(2) : (parseFloat(this.model.total)).toFixed(2);
    },
    addRow() {
      this.paymentItems.push({
        bank: null,
        branch: null,
        account_no: null,
        amount: null,
      });
      // scroll on new added row
      let newRow = document.querySelector(".newRow");
      let wrapper = document.querySelector(".item-table div.v-data-table__wrapper")
      this.$vuetify.goTo(newRow, {container: wrapper});
    },
    async copyData(i) {	
      delete this.paymentItems[i].id;	
      delete this.paymentItems[i].receipt_id;	
      delete this.paymentItems[i].created_at;	
      delete this.paymentItems[i].updated_at;	
      delete this.paymentItems[i].is_deposited;	
      delete this.paymentItems[i].receipt_payment_year;	
      delete this.paymentItems[i].line_number;
      let obj = Object.assign({}, this.paymentItems[i]);
      const previousPaymentDate = new Date(this.paymentItems[i].payment_date);

      // Update the payment item's properties
      if (obj.payment_terms_id.description === "Cheque") {
        obj.cheque_no = String(Number(obj.cheque_no) + 1);
        if (previousPaymentDate.getDate() === new Date(previousPaymentDate.getFullYear(), previousPaymentDate.getMonth() + 1, 0).getDate()) {
          const newPaymentDate = new Date(0).setFullYear(previousPaymentDate.getFullYear(), previousPaymentDate.getMonth() + 1, new Date(previousPaymentDate.getFullYear(), previousPaymentDate.getMonth() + 2, 0).getDate());
          obj.payment_date = new Date(newPaymentDate).toISOString().split('T')[0];
        } else {
          // If previousPaymentDate is not the last day of the month
          const newPaymentDate = new Date(previousPaymentDate.getFullYear(), previousPaymentDate.getMonth() + 1, previousPaymentDate.getDate() + 1);
          obj.payment_date = newPaymentDate.toISOString().split('T')[0];
        }
      }
      this.paymentItems.push(obj);
    },
    deleteRow(index) {
      this.paymentItems.splice(index,1);
      this.receiptPriceCalculation()
    },
    getInvoices(type) {
      if(!type && this.$refs.form.validate()){
        this.receiptInvoicesType = type;
        this.receiptInvoicesDialog = true;
      } else if (type) {
        this.type = type
        this.receiptInvoicesType = type;
        this.receiptInvoicesDialog = true;
      }
    },
    async saveReceipt() {
      if(this.$refs.form.validate() || this.$refs.form.validate() && this.isFieldReadable && this.saveOnlyCategoryStatus){
       this.loading = true;
        let data;
        let paymentItems;
        paymentItems = this.paymentItems.filter((item) => {
          delete item['year'];
          delete item['startDateMenu'];
          if (item.payment_terms_id && Object.keys(item.payment_terms_id).length > 0) {
            item.payment_terms_id = item.payment_terms_id.id;
          }
          item.amount = this.numberFromCommas(item.amount) === '' ? null : this.numberFromCommas(item.amount);
          return item;
        })
        this.model.paymentData = paymentItems;
        delete this.model.discount;
        delete this.model.after_discount;
        delete this.model.vat;
        delete this.model.receiptPayment;
        delete this.model.receiptInvoices;
        if (this.selectedStatus && Object.keys(this.selectedStatus).length > 0) {
          this.model.status = this.selectedStatus.id;
        } else {
          this.model.status = this.selectedStatus;
        }
        if (this.moreDocumentNumber && !this.receiptId) {
          this.model.doc_series_number = this.model.doc_series_number.id
        }
        if (this.selectedGroup && Object.keys(this.selectedGroup).length > 0) {
          this.model.status_one = this.selectedGroup.id;
        }
        if(this.model.paymentData.length > 0) {
          if(this.model.invoiceData && this.model.invoiceData.length > 0) {
            this.model.invoiceData.filter((item) => {
              item.amount_paid = this.numberFromCommas(item.amount_paid) === '' ? null : this.numberFromCommas(item.amount_paid);
              return item;
            })
          }

          this.model.with_holding_tax = this.numberFromCommas(this.model.with_holding_tax) === '' ? null : this.numberFromCommas(this.model.with_holding_tax);
          
          if(this.$route.params.receipt_id) {
            // save only update model value using changedFormData from utils
            this.model.status = 1;
            let saveData = changedFormData(this.model, this.originModel);
            saveData.receipt_date = parseDateYYYYMMDD(this.model.receipt_date)
            this.isProgressCircular = true;
            data = await this.$store.dispatch('receipt/EditReceipt', {receipt_id: this.$route.params.receipt_id, receipt: saveData}).catch((e) => {
              this.loading = false;
              console.log(e)
            });
            if(data) {
              this.$refs.form.reset();
              this.loading = false;
              this.receiptInvoicesType = null;
              this.receiptInvoicesModel = null;
              this.receiptInvoicesDialog = false;
              this.paidTotal = null;
              this.updatedDocuments = null;
              await this.onMounteUpdated();
            }
            this.isProgressCircular = false;
          } else {
              this.model.status = 1;
              data = await this.$store.dispatch('receipt/SetReceipt', this.model).catch((e) => {
              this.loading = false;
              console.log(e)
            });
            if(data) {
              this.$refs.form.reset();
              this.loading = false;
              if (this.accessRight.includes('edit')) {
                this.model.status = 1;
                await this.$router.push('/receipt/editReceipt/' + data.data.id).catch(()=>{});
                this.$store.commit('SET_BOOKKEEPING_CONFIRMATION', data.data.abc);
              } else {
                this.callBackFunction();
              }
            }
          }
        } 
        else {
          this.message = "formRules.paymentItemAlert";
          this.alert = true;
          this.loading = false;
        }
      } else {
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    },
    async deleteItem() {
      this.isProgressCircular = true;
      await this.$store.dispatch('receipt/DeleteReceiptItemById', this.$route.params.receipt_id);
      this.$router.push('/receipt/editReceipt/' + this.$route.params.receipt_id).catch(()=>{});
      this.model.status = 2
      this.originModel.status = 2
      this.selectedStatus = 2
      this.isProgressCircular = false;
    }
  }
}
</script>
<style lang="scss" scoped>
.date-input ::v-deep input {
  font-size: 12px;
}
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
}
</style>
